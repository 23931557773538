import "./App.css";
import Landing from "./components/Landing";
import {Route, Routes} from "react-router-dom";
import "./lib/i18n";
import Contest from "./components/Contest";
import Dashboard from "./components/Dashboard";
import Redirect from "./components/Redirect";
import Logout from "./components/Logout";
import CodeView from "./components/CodeView";
function App() {
  
  return (

    <Routes>
          <Route path="/logout" element= {<Logout />}></Route>
          <Route path="/redirect" element= {<Redirect />}></Route>
          <Route path="/" element = {<Landing />} />
          <Route path="/contest" element = {<Contest />} />
          <Route path="/dashboard" element = {<Dashboard />} />
          <Route path="/codeView" element = {<CodeView />} />
      </Routes>
)
  // return(<Landing />)
}

export default App;
