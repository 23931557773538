import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from 'react-router-dom';
const Redirect = () => {
    const [cookies, setCookie] = useCookies(["uid", "token", "utype"]);
    const [searchParams, setSearchParams] = useSearchParams({token: "", uid: -1, utype:'', url:''});
    const uid = searchParams.get('uid');
    const url = searchParams.get('url');
    const token = searchParams.get('token');
    const utype = searchParams.get('utype');
    
    function handleCookie() {
        setCookie("uid", uid, {
          path: "/"
        });
        setCookie("token", token, {
            path: "/"
        });
        setCookie("utype", utype, {
            path: "/"
        });
      }

    useEffect(() => {
        handleCookie();
        window.location.href = url;
    }, []);
    
    return (
    <>
    </>
    );
};

export default Redirect;

