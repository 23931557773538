import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from 'react-router-dom';
const Logout = () => {
    const [cookies, setCookie] = useCookies(["uid", "token", "utype"]);
    const [searchParams, setSearchParams] = useSearchParams({url:''});
    const url = searchParams.get('url');
    
    function handleCookie() {
        setCookie("uid", "-1", {
          path: "/"
        });
        setCookie("token", "-1", {
            path: "/"
        });
        setCookie("utype", "-1", {
            path: "/"
        });
      }

    useEffect(() => {
        handleCookie();
        window.location.href = url;
    }, []);
    
    return (
    <>
    </>
    );
};

export default Logout;

