import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useTranslation, Trans } from 'react-i18next';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CPagination,
  CPaginationItem
} from '@coreui/react'
import { useSearchParams } from 'react-router-dom';
import WidgetsDropdown from './WidgetsDropdown'
import { useCookies } from "react-cookie";

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [env, setEnv] = useState(null);
  const [cookies, setCookie] = useCookies();
  const user_id = cookies.uid;
  const [qCount, setQCount] = useState([]);
  const [sCount, setSCount] = useState([]);
  const [ranking, setRanking] = useState(null);
  const [score, setScore] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [contestInfo, setContestInfo] = useState(null);
  const [contestant, setContestant] = useState([]);
  const [submission, setSubmission] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({cid: null, language:'zh'});
  const cid = searchParams.get('cid');
  const lan = searchParams.get('language');

  function processUser(response){
    let temp = [];
    {response.map(c => {
      let e = c.email.split('@')
      e = e[0][0] + '*'.repeat(e[0].length - 2)  + e[0][e[0].length - 1] + '@' + e[1]
      let t = {
        avatar: { status: 'success' },
        user: {
          email: e,
          score: c.point,
          name: c.name       
        }
      }
      temp.push(t)
    })}
    setContestant(temp);
    temp = response.filter(function(element){
      return element.id == user_id;
    })
    setScore(temp[0].point)
    setRanking(response.findIndex(function(element){
      return element.id == user_id;
    }) + 1);
  }

  function processContestant(response){
    let temp = [];
    {response.map(c => {
      let e = c._contestant.email.split('@')
      e = e[0][0] + '*'.repeat(e[0].length - 2)  + e[0][e[0].length - 1] + '@' + e[1]
      let t = {
        avatar: { status: 'success' },
        user: {
          email: e,
          score: c.__point,
          name: c._contestant?c._contestant.name:""       
        }
      }
      temp.push(t)
    })}
    setContestant(temp);
    temp = response.filter(function(element){
      return element.user_id == user_id;
    })
    setScore(temp[0].__point)
    setRanking(response.findIndex(function(element){
      return element.user_id == user_id;
    }) + 1);
  }

  function contestSubmissionCount(response){
    let temp = [];
    temp.push(
      response.filter(function(element){
      return element._problem_submission._problem.difficulty == "beginner";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem_submission._problem.difficulty == "easy";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem_submission._problem.difficulty == "medium";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem_submission._problem.difficulty == "hard";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem_submission._problem.difficulty == "expert";
    }).length)
    console.log(temp);
    setSCount(temp);
  }

  function submissionCount(response){
    let temp = [];
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "beginner";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "easy";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "medium";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "hard";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "expert";
    }).length)
    console.log(temp);
    setSCount(temp);
  }

  function questionCount_helloWorld(response){
    let temp = [];
    temp.push(
      response.filter(function(element){
      return element.difficulty == "beginner";
    }).length)
    temp.push(
      response.filter(function(element){
      return element.difficulty == "easy";
    }).length)
    temp.push(
      response.filter(function(element){
      return element.difficulty == "medium";
    }).length)
    temp.push(
      response.filter(function(element){
      return element.difficulty == "hard";
    }).length)
    temp.push(
      response.filter(function(element){
      return element.difficulty == "expert";
    }).length)
    console.log(temp);
    setQCount(temp);
  }


  function questionCount(response){
    let temp = [];
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "beginner";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "easy";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "medium";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "hard";
    }).length)
    temp.push(
      response.filter(function(element){
      return element._problem.difficulty == "expert";
    }).length)
    console.log(temp);
    setQCount(temp);
  }

  function getContestInfo(api){
    const options = {
      method: api.method,
      headers: {
        "content-type": "application/json"
      },
      url: api.url,
      data: {
        "cid": cid
      },
    };
    axios
    .request(options)
    .then(function (response) {
      console.log(response)
      setContestInfo(response.data.contest)
      questionCount(response.data.contest._contest_problem)
      processContestant(response.data.contest._contest_contestant)
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }

  function processContestSubmissions(s){
    setSubmission(s)
    s = s.filter(function(item, index){
      return index === s.findIndex(function(obj){
        return item._problem_submission.problem_id === obj._problem_submission.problem_id;
      })
    })
    contestSubmissionCount(s)
  }

  function processSubmissions(s){
    setSubmission(s)
    s = s.filter(function(item, index){
      return index === s.findIndex(function(obj){
        return item.problem_id === obj.problem_id;
      })
    })
    submissionCount(s)
  }

  function getInfo(api){
    const options = {
      method: api.method,
      headers: {
        "content-type": "application/json"
      },
      url: api.url.replace("{user_id}", user_id),
      data: {
        "user_id": user_id,
      },
    };
    axios
    .request(options)
    .then(function (response) {
      console.log(response)
      setUserInfo(response.data.user)
      processSubmissions(response.data.problem_submission)
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }

  function getRanking(api){
    const options = {
      method: api.method,
      headers: {
        "content-type": "application/json"
      },
      url: api.url,
    };
    axios
    .request(options)
    .then(function (response) {
      console.log(response)
      processUser(response.data.helloworld.contestant)
      questionCount_helloWorld(response.data.helloworld.problem)
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }

  function getUserInfo(api){
    const options = {
      method: api.method,
      headers: {
        "content-type": "application/json"
      },
      url: api.url.replace("{user_id}", user_id),
      data: {
        "cid": cid,
        "user_id": user_id
      },
    };
    axios
    .request(options)
    .then(function (response) {
      console.log(response)
      setUserInfo(response.data.user)
      processContestSubmissions(response.data.contest_submission)
      
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }

  function getEnv(){
    const options = {
      method: "GET",
      headers: {
        "content-type": "application/json"
      },
      url: 'https://api.bricks.academy/api:api_env/r'
    };
    axios
    .request(options)
    .then(function (response) {
      if (cid > 0){
        getUserInfo(response.data.endpoint.stats.contest.r_submission);
        getContestInfo(response.data.endpoint.stats.contest.r_contest);
      }
      else{
        getInfo(response.data.endpoint.stats.helloworld.r_submission);
        getRanking(response.data.endpoint.stats.helloworld.r_helloworld);
      }
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }

  useEffect(() => {
    getEnv();
  },[]);
  
  const difficultyTitle = [
    { title: 'Beginner', color: "text-green-500"},
    { title: 'Easy', color: "text-yellow-400"},
    { title: 'Medium', color: "text-orange-400"},
    { title: 'Hard', color: "text-red-600" },
    { title: 'Expert', color: "text-zinc-500" },
  ]

  const difficultyTable = {

        "Novice": {
          textColor: "text-green-800",
          color: "bg-green-300"
        },
        "Standard": {
          textColor: "text-yellow-800",
          color: "bg-yellow-300"
        },
        "medium": {
          textColor: "text-orange-800",
          color: "bg-orange-300"
        },
        "Open": {
          textColor: "text-red-800",
          color: "bg-red-300"
        }
      }

  return (
    <>
      <div className="relative">
        <div className="p-4 bg-gradient-to-tr from-purple-900 to-purple-100 h-28">

        </div>
        <div className="h-32 bg-gradient-to-r from-slate-100 to-slate-300">
        </div>
        <div className="p-3 absolute top-2/3 right-1/2 translate-x-1/2 -translate-y-1/2 h-68 w-[80%]">
          <div className= "flex flex-row justify-around" >
              {userInfo? <div className="w-[45%] bg-white rounded p-3">
                <h1 className="text-3xl font-bold capitalize">
                  {userInfo.name}
                </h1>
                <hr></hr>
                <div className="p-2">
                    <p className="font-bold">
                      <Trans>Email</Trans>: {userInfo.email}
                    </p>
                    <p className="font-bold">
                      <Trans>Score</Trans>: {score}
                    </p>
                </div></div>:""}
                {contestInfo? <div className="w-[45%] bg-white rounded p-3">
                  <h1 className="text-3xl font-bold ">
                    {contestInfo.title}
                  </h1>
                        <hr></hr>
                        <div className="p-2">
                            <h6 className="mb-3 font-bold">
                                <Trans>Group</Trans>:{' '} 
                                <span className={difficultyTable[contestInfo.level].color + ' ' + difficultyTable[contestInfo.level].textColor + ' p-1 rounded'}>
                                    {contestInfo.level}
                                </span>
                            </h6>
                            <p className="font-bold">
                              <Trans>Start Date</Trans>: {contestInfo.start_date}
                            </p>
                            <p className="font-bold">
                              <Trans>End Date</Trans>: {contestInfo.end_date}
                            </p>
                        </div></div>:""}
              </div>
            </div>
          </div>
      <div className="p-20 bg-gradient-to-r from-slate-100 to-slate-300">

      <WidgetsDropdown 
        ranking = {ranking}
        score = {score}
        sCount = {sCount}
      />

      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader><Trans>Submissions Data</Trans></CCardHeader>
            <CCardBody className="p-0">
              <CRow>
                  <div className="lg:flex ">
                  {difficultyTitle.map((item, index) => (

                      <div className="progress-group pb-4 p-3 m-0 w-[100%]" style={{"borderStyle": "solid",
                        "borderWidth": "1px", "borderColor":"rgba(0, 0, 21, 0.175)"}} key={index + 1}>
                        <div className="progress-group-header mb-2">
                          <span className={"text-xl font-black " + item.color}>{item.title}</span>
                          <span className="ms-auto fw-semibold">
                            <span className="text-xl font-black">{sCount? sCount[index]: ""}</span>/{qCount? qCount[index]: ""}{' '}
                            <span className="text-medium-emphasis small">({sCount && qCount? Math.round((sCount[index] / qCount[index]) * 100): ""}%)</span>
                          </span>
                        </div>
                        <div className="progress-group-bars">
                          <CProgress thin color="success" value={Math.round((sCount[index] / qCount[index]) * 100)} />
                        </div>
                      </div>

                  ))}
                  </div>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      
      <div className="flex">
        <div className="w-[60%] mr-5">
          <CCard className="mb-4">
            <CCardHeader><Trans>Submissions</Trans></CCardHeader>
            <CCardBody>
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell>
                      <Trans>Title</Trans>
                    </CTableHeaderCell>
                    <CTableHeaderCell className="text-center"><Trans>Difficulty</Trans></CTableHeaderCell>
                    <CTableHeaderCell className="text-center"><Trans>Score</Trans></CTableHeaderCell>
                    <CTableHeaderCell className="text-center"><Trans>Submission Time</Trans></CTableHeaderCell> 
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {submission.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell>
                        {item._problem_submission?item._problem_submission._problem.title_zh: item._problem.title_zh}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {item._problem_submission?item._problem_submission._problem.difficulty: item._problem.difficulty}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {item._problem_submission?item._problem_submission._user_point_log.value: item._user_point_log.value}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {new Intl.DateTimeFormat('en-CN', {year: 'numeric'}).format(item.created_at)}/
                        {new Intl.DateTimeFormat('en-CN', {month: '2-digit'}).format(item.created_at)}/
                        {new Intl.DateTimeFormat('en-CN', {day: '2-digit'}).format(item.created_at)}{" "}
                        {new Intl.DateTimeFormat('en-CN', {hourCycle: "h24", hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.created_at)}
                      </CTableDataCell>

                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </div>

        <div className='w-[40%]'>
          <CCard className="mb-4">
            <CCardHeader><Trans>Ranking</Trans></CCardHeader>
            <CCardBody>
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell className="text-center">
                      <Trans>Rank</Trans>
                    </CTableHeaderCell>
                    <CTableHeaderCell><Trans>User</Trans></CTableHeaderCell>
                    <CTableHeaderCell><Trans>Score</Trans></CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>

                  {contestant.map((item, index) => (
                    index / 10 + 1 >= currentPage && Math.floor(index / 10) + 1 <= currentPage?
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell className="text-center">
                        {index + 1}
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{item.user.name}</div>
                        <div className="small text-medium-emphasis">
                          {item.user.email}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        {item.user.score}
                      </CTableDataCell>
                    </CTableRow>:""
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
            <div className="ml-auto mr-auto cursor-pointer">
            <CPagination aria-label="Page navigation example">
              <CPaginationItem aria-label="Previous" onClick={function(){if(currentPage>1){setCurrentPage(currentPage - 1)}}}>
                <span aria-hidden="true">&laquo;</span>
              </CPaginationItem>
              {currentPage == 1?
                <>
                <CPaginationItem active>{currentPage}</CPaginationItem>
                {contestant.length > 10?<CPaginationItem onClick={function(){setCurrentPage(currentPage + 1)}}>{currentPage + 1}</CPaginationItem>:""}
                {contestant.length > 20?<CPaginationItem onClick={function(){setCurrentPage(currentPage + 2)}}>{currentPage + 2}</CPaginationItem>:""}
                </>:""
              }
              {currentPage > 1 && currentPage < Math.floor((contestant.length - 1) / 10) + 1 ?
                <>
                <CPaginationItem onClick={function(){setCurrentPage(currentPage - 1)}}>{currentPage - 1}</CPaginationItem>
                <CPaginationItem active>{currentPage}</CPaginationItem>
                <CPaginationItem onClick={function(){setCurrentPage(currentPage + 1)}}>{currentPage + 1}</CPaginationItem>
                </>:""
              }
              {currentPage == Math.floor((contestant.length - 1) / 10) + 1  && contestant.length > 10?
                <>
                {contestant.length > 20?<CPaginationItem onClick={function(){setCurrentPage(currentPage - 2)}}>{currentPage - 2}</CPaginationItem>:""}
                {contestant.length > 10?<CPaginationItem onClick={function(){setCurrentPage(currentPage - 1)}}>{currentPage - 1}</CPaginationItem>:""}
                <CPaginationItem active>{currentPage}</CPaginationItem>
                </>:""
              }
              <CPaginationItem aria-label="Next" onClick={function(){if(currentPage < Math.floor((contestant.length - 1) / 10) + 1){setCurrentPage(currentPage + 1)}}}>
                <span aria-hidden="true">&raquo;</span>
              </CPaginationItem>
            </CPagination>
            </div>

          </CCard>
        </div>
      </div>




      </div>
    </>
  )
}

export default Dashboard
