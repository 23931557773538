import React from "react";
import { useEffect, useState } from "react";
import { CAccordionBody } from '@coreui/react';
import { CAccordionHeader } from '@coreui/react';
import { CAccordionItem } from '@coreui/react';
import { CAccordion } from '@coreui/react';
import { classnames } from "../utils/general";
import { useTranslation, Trans } from 'react-i18next';

const Submit = ({ testcase ,code, handleSubmit, submitOutputDetails, submitting, setShowModal}) => {
    const { t, i18n } = useTranslation();
    const [tc, setTc] = useState(null);
    const switchTestcase = (i) =>{
        if (tc && tc === testcase[i]){
            setTc(null);
        } else{
            setTc(testcase[i]);
        }
    }

    async function copyTextToClipboard() {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(b64DecodeUnicode(tc.input_base64));
        } else {
          return document.execCommand('copy', true, b64DecodeUnicode(tc.input_base64));
        }
    }

    function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    const getTestcase = () =>{
        return(
            <div className = "flex flex-col">
                <div className= "flex justify-between font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">
                    <div>
                        <Trans>Input</Trans>
                    </div>
                    <div>
                        <button onClick={copyTextToClipboard}>
                            <img className ='h-5 inline-block mb-1' src={process.env.PUBLIC_URL  + `/copy.png`}></img>
                        </button>
                    </div>

                </div>
                <pre className="px-2 py-2 w-full h-36 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto">
                    {b64DecodeUnicode(tc.input_base64)}
                </pre>
                <div className= "font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">
                    <Trans>Output</Trans>
                </div>
                <pre className="px-2 py-2  w-full h-36 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto">
                    <Trans>{tc.visible? b64DecodeUnicode(tc.output_base64): 'Hidden'}</Trans>
                </pre>
                <pre className="w-full font-normal text-base">
                    <Trans>{tc.visible? b64DecodeUnicode(tc.description_base64): 'Hidden'}</Trans>
                </pre>
                <div className= "font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">
                    <Trans>Status</Trans>
                </div>
                <div>
                    <Trans>{submitOutputDetails.length > 0 ? submitOutputDetails[testcase.indexOf(tc)].status?.description : "-"}</Trans>
                </div>
            </div>
        )
    }
    return (
        <>
        {/* // <CAccordionItem itemKey={3}>
        //     <CAccordionHeader onClick={handleExpand}><span className="font-bold"><img className ='h-5 inline-block mr-1 mb-1'src={process.env.PUBLIC_URL  + `/submit.png`}></img><Trans>Submit</Trans></span></CAccordionHeader>
        //         <CAccordionBody className="p-0"> */}
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
            <div className="relative">
                {/*content*/}
                <div style={{width:"70vh"}} className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                        <Trans>Submit</Trans>
                    </h3>
                    <button
                    className=""
                    onClick={() => setShowModal(false)}
                    >
                    <span className="text-black text-2xl">
                        X
                    </span>
                    </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto">
                    <p className="text-slate-500 text-lg leading-relaxed">
                        <div style={{height:"50vh"}} className='flex flex-col'>
                            <div className = "border-b-2 flex overflow-y-auto" style={{height:"62vh"}}>
                                <div className="w-[20%] border-r-2 overflow-y-auto flex flex-col">
                                    {testcase.map(t => {
                                        return (
                                            <button
                                            onClick={() => switchTestcase(testcase.indexOf(t))}
                                            className={classnames(
                                            "cursor-pointer pt-3 pb-3",
                                            !code ? "opacity-50" : "",
                                            testcase.indexOf(t) === testcase.indexOf(tc)? "bg-slate-100":"",
                                            submitOutputDetails.length < 1?  "border-inherit" : submitOutputDetails[testcase.indexOf(t)].status.description === 'Accepted'? "text-green-600": "text-rose-600",
                                            )}
                                        >

                                            {t.visible? <div><Trans>{'Testcase'}</Trans>{testcase.indexOf(t) + 1}</div>:<div className="mr-2"><img className = "h-4 inline mb-1"src={process.env.PUBLIC_URL  + `/testcase_hidden.png`}></img><Trans>{'Testcase'}</Trans>{testcase.indexOf(t) + 1}</div>}
                                        </button>
                                    );
                                    })}
                                </div>
                                <div className="w-[80%] pl-2 pr-2 pt-2">                                
                                    {(submitOutputDetails && tc) ? <>{getTestcase()}</> : null}
                                </div>
                            </div>
                            <div style={{height:"8vh"}} className="flex">
                                <button
                                    onClick={handleSubmit}
                                    disabled={!code || submitting} 
                                    className={classnames(
                                    "text-gray-800 ml-auto my-2 mr-3 font-bold text-lg py-1 px-4 bg-green-300 inline-flex items-center w-fit",
                                    !code ? "opacity-50" : ""
                                    )}
                                >
                                    {submitting? <img className ='fill-current w-7 h-7 inline-block mb-1'src={process.env.PUBLIC_URL  + `/processing.gif`}></img>: <img className ='fill-current w-7 h-7 inline-block'src={process.env.PUBLIC_URL  + `/compile.png`}></img>}
                                    {submitting? t('Processing') : t("Run")}
                                </button>
                            </div>
                        </div>
                    </p>
                </div>
                {/*footer*/}
                </div>
            </div>
        </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            {/* </CAccordionBody>
        </CAccordionItem> */}
    </>
  );
};

export default Submit;