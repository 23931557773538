export const languageOptions = [
  {
    id: 71,
    name: "Python (3.8.1)",
    label: <div className="flex items-center"><img className="h-5 mr-1" src={process.env.PUBLIC_URL  + '/langs_python.png'}/> Python (3.8.1)</div>,
    value: "python",
  },
  {
    id: 76,
    name: "C++ (Clang 7.0.1)",
    label: "C++ (Clang 7.0.1)",
    value: "cpp",
  }
];
