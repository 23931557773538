import React from "react";

export default function Drawer({ children, isOpen, setIsOpen, handleSubmitOpneAi, apiResponse, loading ,setLoading}) {
  return (
    <main
      className={
        " fixed overflow-auto transform ease-in-out w-[40%] right-10 top-40 rounded border-2 bg-white" +
        (isOpen
          ? " transition-opacity opacity-100 duration-0 translate-x-0  "
          : " transition-all delay-0 opacity-0 translate-x-full  ")
      }
      style={{"height":"50vh"}}
    >
      <section>
        <div className="flex flex-col" style={{"height":"50vh"}}>
            <div className="flex justify-between border-b-2 basis-1/12 p-2">
                <h5 className="m-0">
                    Helio
                </h5>
                <button    
                onClick={() => {
                setIsOpen(false);
                }}>
                    close
                </button>
            </div>
            <div className=" basis-10/12 p-4 border-b-2">
                {apiResponse?  (
                <div
                className="bg-slate-200 px-4 py-2 rounded"
                >
                <pre>
                    {apiResponse}
                </pre>
                </div>
                ): <img className ='fill-current w-7 h-7 inline-block mb-1'src={process.env.PUBLIC_URL  + `/processing.gif`}></img>}
            </div>
            {/* <div className=" basis-1/12 p-2">
                <button
                type="submit"
                onClick={() => {handleSubmitOpneAi(); setLoading(true);}}
                disabled={loading}
                >
                {loading ? "Generating..." : "Generate"}
                </button>
            </div> */}
        </div>
      </section>

    </main>
  );
}
