import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Interweave } from 'interweave';
const QuestionInfo = ({QInfo, testcase, cid}) => {
    const difficultyTable = {
    
        "beginner": {
          textColor: "text-green-800",
          color: "bg-green-300"
        },
        "easy": {
          textColor: "text-yellow-800",
          color: "bg-yellow-300"
        },
        "medium": {
          textColor: "text-orange-800",
          color: "bg-orange-300"
        },
        "hard": {
          textColor: "text-red-800",
          color: "bg-red-300"
        },
        "expert": {
          textColor: "text-zinc-800",
          color: "bg-zinc-300"
        }
  }

    const { t, i18n } = useTranslation();
    function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    } 
    return (
        <>
            {QInfo? <div className="flex p-3 flex-col">

                <div className="rounded-md p-2 mb-2">
                    <div className="flex justify-between">
                        <h1 className="text-2xl font-bold">
                            {cid? "": QInfo.pid + ":"} {QInfo.title_zh}
                        </h1>
                    </div>
                    <hr></hr>
                    <div className="p-2">
                        <h6 className="mb-3">
                            <span className={difficultyTable[QInfo.difficulty].color + ' ' + difficultyTable[QInfo.difficulty].textColor + ' p-1.5 rounded mr-4'}>
                                {QInfo.difficulty} 
                            </span>
                            <span className = "font-bold">
                                {QInfo.point}+
                            </span>
                
                        </h6>
                        <p className='text-lg'>
                            <Interweave content={QInfo.description_html}/>
                        </p>
                    </div>

                </div>

                {testcase? <div className="rounded-md p-2">
                    <h1 className="text-2xl font-bold">
                        <Trans>Example</Trans>
                    </h1>
                    <div className="p-2">
                    {testcase.map(t => {
                        return(
                        <div className="rounded-md p-2 mb-3" style={{background:"#f7f7f7"}}>
                        <h1 className="text-xl font-bold">
                            <Trans>Example</Trans>{testcase.indexOf(t) + 1}: 
                        </h1>
                        <div className="p-2">
                            <div className="flex mb-2">
                                <h1 className='text-lg font-bold mr-2 my-0'><Trans>Input</Trans>:</h1>
                                <button onClick={async function copyTextToClipboard() {
                                    if ('clipboard' in navigator) {
                                    return await navigator.clipboard.writeText(b64DecodeUnicode(t.input_base64));
                                    } else {
                                    return document.execCommand('copy', true, b64DecodeUnicode(t.input_base64));
                                    }
                                }}>
                                    <img className ='h-5 inline-block mb-1' src={process.env.PUBLIC_URL  + `/copy.png`}></img>
                                </button>
                            </div>
                            <pre>
                                <p className="pl-2 m-0">
                                {b64DecodeUnicode(t.input_base64)}
                                </p>
                            </pre>
                            <pre>
                                <h1 className='text-lg font-bold m-0'><Trans>Output</Trans>:</h1>
                                <p className="pl-2 m-0">
                                    <Trans>{t.visible? b64DecodeUnicode(t.output_base64): 'Hidden'}</Trans>
                                </p>
                            </pre>
                        </div>
                        </div>)
                    }
                    )}
                    </div>
                </div>: ""}

            </div> : ""}
        </>
  );
};

export default QuestionInfo;
