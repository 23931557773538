import React from 'react'
import { useTranslation, Trans } from 'react-i18next';
import {
  CRow,
  CCol,
  CWidgetStatsE,
} from '@coreui/react'
import { getStyle } from '@coreui/utils'
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilOptions } from '@coreui/icons'

const WidgetsDropdown = ({ranking, score, sCount}) => {
  return (

    <CRow>
      <CCol sm={12} lg={4}>
        <CWidgetStatsE
          className="mb-3"
          chart={
            <CChartBar
              className="mx-auto"
              style={{ height: '40px', width: '80px', "pointerEvents":"none" }}
              data={{
                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'M'],
                datasets: [
                  {
                    backgroundColor: '#321fdb',
                    borderColor: 'transparent',
                    borderWidth: 1,
                    data: [41, 78, 51, 66, 74, 42, 89, 97, 87, 84, 78, 88, 67, 45, 47],
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
              }}
            />
          }
          title={<Trans>Problems Solved</Trans>}
          value={sCount? sCount[0] + sCount[1] + sCount[2] + sCount[3]: 0}
        />
      </CCol>
      <CCol sm={12} lg={4}>
        <CWidgetStatsE
          className="mb-3"
          chart={
            <CChartLine
              className="mx-auto"
              style={{ height: '40px', width: '80px', "pointerEvents":"none" }}
              data={{
                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'M'],
                datasets: [
                  {
                    backgroundColor: 'transparent',
                    borderColor: '#321fdb',
                    borderWidth: 2,
                    data: [41, 78, 51, 66, 74, 42, 89, 97, 87, 84, 78, 88, 67, 45, 47],
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
              }}
            />
          }
          title={<Trans>Score</Trans>}
          value={score?score:0}
        />
      </CCol>
      <CCol sm={12} lg={4}>
        <CWidgetStatsE
          className="mb-3"
          chart={
            <CChartLine
              className="mx-auto"
              style={{ height: '40px', width: '80px', "pointerEvents":"none"}}
              data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                  {
                    backgroundColor: 'transparent',
                    borderColor: '#321fdb',
                    pointBackgroundColor: '#321fdb',
                    data: [65, 59, 84, 84, 51, 55, 40],
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 3,
                    hitRadius: 10,
                    hoverRadius: 3,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
              }}
            />
          }
          title={<Trans>Ranking</Trans>}
          value={ranking?ranking:0}
        />
      </CCol>
    </CRow>
  )
}

export default WidgetsDropdown
