import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import LanguagesDropdown from "./LanguagesDropdown";
import ThemeDropdown from "./ThemeDropdown";
import FontSizeDropdown from "./FontSizeDropdown";
import { languageOptions } from "../constants/languageOptions";
import { themeOptions } from "../constants/themeOptions";
import { useTranslation, Trans } from 'react-i18next';
import { CAccordionItem } from '@coreui/react'
import { CAccordion } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import ResetCodeModal from "./ResetCodeModal"
import { useSearchParams } from 'react-router-dom';
import Submit from "./Submit";
import QuestionInfo from './QuestionInfo';
import Splitter from 'm-react-splitters';
import { useCookies } from "react-cookie";
import { classnames } from "../utils/general";
import { COffcanvas, COffcanvasBody, COffcanvasHeader,  COffcanvasTitle, CCloseButton, CButton, CTooltip} from '@coreui/react'



const RAPID_API_URL = "https://judge0-ce.p.rapidapi.com/submissions"
const RAPID_API_HOST = "judge0-ce.p.rapidapi.com"
const RAPID_API_KEY = "fbe7df1e99msh10f296f62348e88p18ba83jsn4f2f578fb950"
const lngs = {
  zh: { nativeName: 'Chinese' },
  en: { nativeName: 'English' }
};
const autoSavePeriod = 6000;
const Landing = () => {
  const [env, setEnv] = useState(null);
  const [visible, setVisible] = useState(false)
  const [cookies, setCookie] = useCookies();
  const [isOpen, setIsOpen] = useState(false);
  const user_id = cookies.uid;
  const [lastSavedCode, setLastSavedCode] = useState('');
  const [QInfo, setQInfo] = useState(null);
  const [skeleton, setSkeleton] = useState([]);
  const [sampleCode, setSampleCode] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [fontSize, setFontSize] = useState(24);
  const inputArea = document.querySelector('.inputarea');
  const [language, setLanguage] = useState(languageOptions[0]);
  const [theme, setTheme] = useState(themeOptions[0]);
  const { t, i18n } = useTranslation();
  const [submitExpanded, setSubmitExpanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({pid: -1, language:'zh', cid: -1, contest: ""});
  const cid = searchParams.get('cid');
  const qid = searchParams.get('pid');
  const lan = searchParams.get('language');
  const contest = searchParams.get('contest');
  const [testcase, setTestcase] = useState([]);
  const [code, setCode] = useState('');
  const [customInput, setCustomInput] = useState("");
  const [compileOutputDetails, setCompileOutputDetails] = useState(null);
  const [submitOutputDetails, setSubmitOutputDetails] = useState([]);
  const [processing, setProcessing] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const onSelectChange = (sl) => {
    setLanguage(sl);
    let temp = ''
    {skeleton.map(s => {
      if (s._language.judge0_language_id == sl.id){
        temp = b64DecodeUnicode(s.code_base64)
      }
    }
    )}
    setSampleCode(temp);
  };
  
  const onThemeSelectChange = (t1) => {
    setTheme(t1);
  }
  const onFontSizeSelectChange = (fs) => {
    setFontSize(fs.value);
  };

  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  } 

  function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
  }
  React.useEffect(() => {

    if (inputArea){
      inputArea.addEventListener("keydown", keyPress);
      return () => {
        inputArea.removeEventListener("keydown", keyPress);
      };
    }
  });

  const keyPress = (e) =>{
    if (e.key === "Enter" && e.ctrlKey === true ){
      sendData();
    }
  }

  // const navigate = useNavigate();
  // useEffect(() => {
  //   setInterval(pingServer, 5000);
  //   window.addEventListener("beforeunload", (ev) => 
  //   {  
  //     const options = {
  //       method: "POST",
  //       url: "https://api.bricks.academy/api:dIOXaIX5/online",
  //       data: {
  //           "lastonline": null,
  //           "s_id": 1
  //         },
  //       headers: {
  //       "content-type": "application/json",
  //       },
  //     };
  //     axios
  //     .request(options)
  //     .then(function (response) {
  //       setQuestion(response.data);
  //     })
  //     .catch((err) => {
  //       let error = err.response ? err.response.data : err;
  //       // get error status
  //       console.log("catch block...", error);
  //     });
  //     const date = new Date();
  //     console.log(date);
  //   });
  // }, []);


  
  // function handleRemoveQueryStrings() {
  //   navigate({
  //     pathname: window.location.pathname,
  //     search: '',
  //   });
  // }
  function handleThemeChange(th) {
    const theme = th;

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  

  useEffect(() => {
    if (user_id > 0 && qid > 0){
      getEnv();
    }
    i18n.changeLanguage(lan);
    // handleRemoveQueryStrings();
  },[]);

  function getEnv(){
    const options = {
      method: "GET",
      headers: {
        "content-type": "application/json"
      },
      url: 'https://api.bricks.academy/api:api_env/r'
    };
    axios
    .request(options)
    .then(function (response) {
      console.log(response)
      
      getSession(response.data.endpoint.problem.contest.r_session);
      getQuestion(response.data.endpoint.problem.contest.r_problem);
      setEnv(response.data)
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }
  useEffect(() => {
    if (user_id > 0 && qid > 0 && env){
      const interval = setInterval(autoSave, autoSavePeriod);
      return () => clearInterval(interval);
    }
  },[code, lastSavedCode, env]);

  const autoSave = () => {

    if (code != lastSavedCode){

      saveCode();
      setLastSavedCode(code);
    } 
  }
  
  const saveCode = () =>{
    const options = {
      method: env.endpoint.problem.contest.u_session.method,
      url: env.endpoint.problem.contest.u_session.url,
      data: {
        "user_id": user_id,
        "pid": qid,
        "code": code,
        "cid": cid
      }
    };
    axios
    .request(options)
    .then(function (response) {
      //pass
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }

  const getSession = (api) => {
    const options = {
      method: api.method,
      url: api.url,
      data: {
        "user_id": user_id,
        "pid": qid,
        "cid": cid
      }
    };
    axios
    .request(options)
    .then(function (response) {
      if (response.data){
        setCode(response.data.code);
        setLastSavedCode(response.data.code);
      }
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }

  function getQuestion(api){
    const options = {
      method: api.method,
      headers: {
        "content-type": "application/json"
      },
      url: api.url,
      data: {
        "pid": qid
      },
    };
    axios
    .request(options)
    .then(function (response) {
      let temp = ''
      {response.data._skeleton.map(s => {
        if (s._language.judge0_language_id == language.id){
          temp = b64DecodeUnicode(s.code_base64)
        }
      }
      )}
      setQInfo(response.data);
      setSkeleton(response.data._skeleton);
      setSampleCode(temp);
      setTestcase(response.data._testcase);
    })
    .catch((err) => {
      let error = err.response ? err.response.data : err;
      // get error status
      console.log("catch block...", error);
    })
  }


  useEffect(() => {
    if (enterPress && ctrlPress) {
      sendData();
    }
  }, [ctrlPress, enterPress]);

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const handleSubmit = () => {
    saveCode();
    setSubmitOutputDetails([]);
    setSubmitting(true);
    
    let form = []
    
    {testcase.map(t => {
      const newItem = {
        language_id: language.id,
        // encode source code in base64
        expected_output: t.output_base64,
        source_code: b64EncodeUnicode(code),
        stdin: t.input_base64,
      }
      form.push(newItem);
    }
    )}
    const formData = {
      submissions: form
    }

    const options = {
      method: "POST",
      url: RAPID_API_URL + '/batch',
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        let token = '';
        {response.data.map(d => {
          token = token + d.token + ',';
        })}

        checkStatus_Submit(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        console.log("status", status);
        if (status === 429) {
          console.log("too many requests", status);

          showErrorToast(
            `too many requests`,
            10000
          );
        }
        setSubmitting(false);
        console.log("catch block...", error);
      });
  };

  const handleCompile = () => {
    setCompileOutputDetails("");
    setProcessing(true);
    const formData = {
      language_id: language.id,
      // encode source code in base64
      source_code: b64EncodeUnicode(code),
      stdin: b64EncodeUnicode(customInput),
    };

    const options = {
      method: "POST",
      url: RAPID_API_URL,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        console.log("status", status);
        if (status === 429) {
          console.log("too many requests", status);

          showErrorToast(
            `too many requests`,
            10000
          );
        }
        setProcessing(false);
        console.log("catch block...", error);
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 200);
        return;
      } else {
        setProcessing(false);
        setSubmitting(false);
        setCompileOutputDetails(response.data);
        showSuccessToast(`Compiled Successfully!`);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      setSubmitting(false);
      showErrorToast();
    }
  };

  const checkStatus_Submit = async (token) => {
    const options = {
      method: "GET",
      url: RAPID_API_URL + "/batch",
      params: { tokens: token, base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let data = response.data.submissions;
      let processing = false
      {data.map(d => {
        if (d.status?.id === 1 || d.status?.id === 2){
          processing = true
        }
      })}

      // Processed - we have a result
      if (processing) {
        // still processing
        setTimeout(() => {
          checkStatus_Submit(token);
        }, 200);
        return;
      } else {

        setProcessing(false);
        setSubmitting(false);
        setSubmitOutputDetails(response.data.submissions);
        sendSubmit(response.data.submissions);
        showSuccessToast(`Compiled Successfully!`);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      setSubmitting(false);
      showErrorToast();
    }
  };

  useEffect(() => {
    defineTheme("oceanic-next");
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(t(msg || `Compiled Successfully!`), {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const sendSubmit = (submissions) => {
    if (checkSubmitStatus(submissions)){
      let submission = submissions[submissions.length - 1]
      const options = {
        headers: {
          "content-type": "application/json"
        },
        method: env.endpoint.problem.contest.c_submission.method,
        url: env.endpoint.problem.contest.c_submission.url,
        data: {
          "pid": qid,
          "user_id": user_id,
          "code": code,
          "code_base64": b64EncodeUnicode(code),
          "code_size": code.length,
          "token": submission.token,
          "execution_time": submission.time,
          "execution_memory": submission.memory,
          "cid": cid
        }
      };
      axios
      .request(options)
      .then(function (response) {
        window.top.location.replace(env.redirect.problem_submission.contest.replace("{slug}", contest));
      })
      .catch((err) => {
        console.log("err", err);
        setProcessing(false);
        showErrorToast();
      })
    }
  }
  const checkSubmitStatus = (submissions)=>{
    for (let i = 0; i < submissions.length; i++){
      if (submissions[i].status.description !== 'Accepted'){
        return false
      }
    }
    return true
  }
  
  const showErrorToast = (msg, timer) => {
    toast.error(t(msg || `Something went wrong! Please try again.`), {
      position: "bottom-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const sendData = () => {
    saveCode();
    setCompileOutputDetails("");
    setProcessing(true);
    const options = {
      headers: {
        "content-type": "application/json"
      },
      method: env.endpoint.problem.contest.e_execute.method,
      url: env.endpoint.problem.contest.e_execute.url,
      data: {
        "language_id": language.id,
        // encode source code in base64
        "code": b64EncodeUnicode(code),
        "stdin": b64EncodeUnicode(customInput),
      }
    };
    axios
    .request(options)
    .then(function (response) {
      setProcessing(false);
      setCompileOutputDetails(response.data.response.result);
      showSuccessToast(`Compiled Successfully!`);
    })
    .catch((err) => {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
    })
  };

  const handleSubmitExpand = () =>{
    if (submitExpanded == true){
      setSubmitExpanded(false)
    }else{
      setSubmitExpanded(true)
    }
  }

  const resetCode = () => {
    setCode(sampleCode);
    setShowModal(false);
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showModal ? (
       <Submit
        setShowModal={setShowModal}
        handleExpand={handleSubmitExpand}
        testcase={testcase} 
        code = {code}
        handleSubmit = {handleSubmit}
        submitOutputDetails = {submitOutputDetails}
        submitting = {submitting}
       />
      ) : null}

      {env?<Splitter
          position="vertical"
          primaryPaneMaxWidth="40%"
          primaryPaneMinWidth="0%"
          primaryPaneWidth= {env.interface.problem.contest.panel_l_size_percent + "%"}
      >    
          <div className="flex flex-col  border-r-2 h-screen overflow-y-auto">
            < QuestionInfo 
              cid = {cid}
              testcase={testcase}
              QInfo={QInfo}
            />
          </div>



          <div className="flex pt-1 flex-col">
                <div className="flex pt-1 flex-col">
                  <div className = "block w-[100%] px-3 py-1">
                    <div className = "flex justify-between" style={{height:"7vh"}}>
                      <div className="flex">
                        <div className="mr-2 mt-auto mb-auto">
                          <LanguagesDropdown onSelectChange={onSelectChange} />
                        </div>
                      </div>

                      <div className="flex">
                        <div className="mt-auto mb-auto">
                            <button
                              onClick={sendData}
                              disabled={!code || processing}
                              style={{
                                borderLeft: "1px black solid",
                                borderTop: "1px black solid",
                                borderBottom: "1px black solid"
                              }}
                              className={classnames(
                                "bg-green-300 text-gray-800 font-bold py-1 px-3 rounded-tl-lg rounded-bl-lg flex items-center",
                                !code || processing? "opacity-50" : ""
                              )}
                            >
                              {<img className ='w-4 h-4 mt-1 mb-1 block'src={process.env.PUBLIC_URL  + `/compile.png`}></img>}
                              <span className="ml-2 lg:block hidden"><Trans>Run</Trans></span>  
                            </button>
                          </div>

                          <div className="mt-auto mb-auto">
                          <CTooltip
                            content={<Trans>Submit</Trans>}
                            placement="top"
                          >
                            <button
                              disabled={!code}
                              style={{
                                border: "1px black solid",
                              }}
                              data-tooltip-target="tooltip-default" 
                              className={classnames("bg-green-400 text-gray-800 rounded-tr-lg rounded-br-lg px-2 py-1.5", !code? "opacity-50" : "")}
                              onClick={() => setShowModal(true)}
                            >
                              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
                                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                                </path>
                              </svg>
                            </button>
                          </CTooltip>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="mr-2 mt-auto mb-auto">
                          <FontSizeDropdown onSelectChange={onFontSizeSelectChange} />
                        </div>
                        <div className="mr-2 mt-auto mb-auto">
                          <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
                        </div>
                      </div>

                    </div>
                  
                    <div className="items-end codeWindow " style={{height:"54vh"}}>
                          <CodeEditorWindow
                            fontSize = {fontSize}
                            code={code}
                            onChange={onChange}
                            language={language?.value}
                            theme={theme.value}
                          />
                    </div>

                    <div className='flex' style={{height:"35vh"}}>

                          <div className="relative w-[30%] mr-3">
                            <CustomInput
                              code = {code}
                              processing={processing}
                              sendData={sendData}
                              customInput={customInput}
                              setCustomInput={setCustomInput}
                            />
                          </div>

                          <OutputWindow compileOutputDetails={compileOutputDetails} />

                      </div>
                  </div>
                  {/* <div className = {`w-[100%] md:fixed md:top-20 md:right-20 ${submitExpanded? "md:w-[30%]": "md:w-fit"}`}> */}
                  {/* <CAccordion activeItemKey={1} onClick={}> */}
                  
                  {/* {(user_id > 0) && (qid > 0) && <CAccordion className={submitExpanded? "w-[100%]": "ml-auto w-32 transition-all duration-700"}>
                    <CAccordionItem itemKey={1}>
                      <Submit 
                      handleExpand={handleSubmitExpand}
                      testcase={testcase} 
                      code = {code}
                      handleSubmit = {handleSubmit}
                      submitOutputDetails = {submitOutputDetails}
                      submitting = {submitting}/>
                      </CAccordionItem>
                  </CAccordion>} */}
                  {/* </div> */}
                </div>
              </div>
      </Splitter>:""}
  </>
  );
};
export default Landing;
