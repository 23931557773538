import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
const CodeView = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterValue, setFilterValue] = useState(null);

    const handleUserChange = (event) =>{
        setFilterValue(event.target.value);
    }

    const handleRemoveItem = () => {
        setCurrentUser(null)
    };

    const handleAddItem = (item) => {
        setCurrentUser(item.uid);
    };
    
    useEffect(() => {
        setFilteredUsers(users.filter(item => item.uid.includes(filterValue)))
    }, [filterValue]);

    const users = [
        { user_name: "Eric Un", uid:"16", lastOnline:"2023/7/7,20:30", title_zh: "2Sum", pid:'2026', code:"print(123)print(123)print(123)print(123)print(123)print(123)print(123)print(123)print(123)print(123)print(123)"},
        { user_name: "Peter Chan", uid:"17", lastOnline:"2023/7/5,22:30", title_zh: "Hello World", pid:'2026', code:"print(123)"},
        { user_name: "Sam", uid:"13", lastOnline:"2023/7/7,08:30", title_zh: "Hello World", pid:'2026', code:"print(123)"},
        { user_name: "Denise", uid:"14", lastOnline:"2023/7/1,2:30", title_zh: "Hello World", pid:'2026', code:"print(123)"},
        { user_name: "Belle", uid:"11", lastOnline:"2023/7/1,3:30", title_zh: "Hello World", pid:'2026', code:"print(123)"},
      ]
    
      return (
        <>
            <div className="p-3 m-3">
                <span>
                    search by uid: 
                </span>
                <input
                    type="text"
                    className = {"border-2"}
                    onChange={handleUserChange}
                />
            </div>
            <div className="flex flex-wrap text-xs overflow-y-auto h-56 border-2 m-3">
                {filteredUsers.map((item, index) => (
                    <div key={index} className={"p-2 m-2 cursor-pointer rounded h-fit" + (currentUser === item.uid?" bg-slate-300 ":" bg-slate-50 ")} onClick={() => handleAddItem(item)}>
                        <div>
                            Name: {item.user_name}
                        </div>
                        <div>
                            Id: {item.uid}
                        </div>
                        <div>
                            LastOnline: {item.lastOnline}
                        </div>
                    </div>
                ))}
            </div>
            <hr></hr>
            {
                currentUser? 
                <div className="w-[100%] h-[100%]">
                    <div className="p-3 m-3 bg-slate-50" onClick={() => handleRemoveItem()}>
                        <div className="flex">
                            <div className="p-2">
                                Name: {users.find(x => x.uid === currentUser).user_name}
                            </div>
                            <div className="p-2">
                                Id: {users.find(x => x.uid === currentUser).uid}
                            </div>
                            <div className="p-2">
                                LastOnline: {users.find(x => x.uid === currentUser).lastOnline}
                            </div>
                            <div className="p-2">
                                title_zh: {users.find(x => x.uid === currentUser).title_zh}
                            </div>
                            <div className="p-2">
                                pid: {users.find(x => x.uid === currentUser).pid}
                            </div>
                        </div>
                        <hr></hr>
                        <pre className="bg-slate-300 p-3">
                            {users.find(x => x.uid === currentUser).code}
                        </pre>
                    </div>
            </div>
                
                
                :""
            }
        </>
      )
}

export default CodeView;